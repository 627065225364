<template>
  <div>
    <!-- <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
          :plan-options="ss" @refetch-data="refetchData" />
    
        <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
          :role-options="roleOptions" :plan-options="ss" :status-options="statusOptions" /> -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex justify-content-between w-100">
          <!-- Per Page -->
          <b-col md="6" class="d-flex mt-1">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              v-model="per_page"
              class="per-page-selector d-inline-block mx-50 w-25"
              :options="['5', '10', '20', '50', '100']"
              @input="changePagination()"
            />
            <!-- Search -->
          </b-col>
          <b-col md="6" class="d-flex mt-1 ml-100">
            <div class="w-100 d-flex justify-content-end">
              <div v-if="searchTerm.type == 'dropdown'">
                <v-select
                  style="width: 200px;"
                  placeholder="Search..."
                  :options="searchTerm.storename ? getOptions(searchTerm.storename):searchTerm.options"
                  :label="searchTerm.displayName"
                  class="d-inline-block mr-1"
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
              <div v-else>
                <b-form-input
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
              <div class="ml-1">
                <v-select
                  style="width: 150px !important"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :searchable="false"
                  v-model="searchTerm"
                  placeholder="Select"
                  class="per-page-selector d-inline-block mx-50"
                  label="name"
                  tbody-tr-class="custom-table-row"
                  :options="searchTermOptions"
                  @input="handleSearchType()"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        :busy="isLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="staticUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="onSortChanged"
      >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
        <template #cell(id)="data">
          <span class="text-primary">
            {{ data.index + 1 }}
          </span>
        </template>

        <template #cell(suspendDate)="data">
          <p class="font-weight-bolder text-danger">{{ formatDate(data && data.item?data.item.suspend_date:'') }}</p>
        </template>
        
        <template #cell(f_name)="data">
          <div
            class="cursor-pointer font-weight-bolder"
            style="font-size: 15px; font-family: 'Public Sans', sans-serif"
          >
            <span class="mr-1">
              <b-avatar
                size="32"
                :src="data.item.profilephoto"
                :text="data.item.f_name.substring(0, 1)"
              />
            </span>
            {{ data.item.f_name }}
          </div>
        </template>
        <template #cell(specialized)="data">
          <div class="d-flex w-100 align-items-center font-weight-bold">
            <div>
              <p
                class="m-0"
                style="
                  font-size: 15px;
                  font-weight: 400;
                  font-family: 'Public Sans', sans-serif;
                "
              >
                {{ setspecialization(data.item.all_specialization) }}
              </p>
            </div>
          </div>
        </template>

        <template #cell(phone)="data">
          <div
            class="d-flex align-items-center"
            style="
              font-size: 15px;
              font-weight: 400;
              font-family: 'Public Sans', sans-serif;
            "
          >
            <span>{{ data.item.phone }}</span>
          </div>
        </template>
        <template #cell(Action)="data">
          <b-badge @click="handleUnsuspend(data.item.id)" class="mr-1 font-weigth-bolder cursor-pointer" style="font-size:15px" variant="light-danger">
            Unsuspend
          </b-badge>
          <feather-icon
            icon="EyeIcon"
            size="22"
            class="m-1"
            @click="openTrainerProfile(data.item)"
            style="cursor: pointer"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <div class="w-100 d-flex justify-content-between p-1">
            <!-- Pagination -->
            <div>
              <span class="text-nowrap">
                Showing {{ pagination.from }} to {{ pagination.to }} of
                {{ pagination.total }}</span
              >
            </div>
            <b-pagination
              :total-rows="paginationTotal"
              :per-page="per_page"
              v-model="paginationValue"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="changePagination()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-row>
      </div>
    </b-card>
  </div>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BProgress,
  BFormGroup,
  BFormFile,
  BForm,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BAvatarGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import _ from "lodash";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    moment,
    BAvatarGroup,
    BSpinner,
    required,
    BRow,
    BForm,
    BInputGroupAppend,
    BInputGroup,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BProgress,
    vSelect,
    BFormFile,
    flatPickr
  },
  data() {
    return {
      // tableColumns: ['ID', 'Trainer', 'Client', 'SpecializedInWhichArea', 'Contact', 'Action'],
      tableColumns: [
        // { key: 'id', label: '#', sortable: true },
        // { key: 'invoiceStatus', sortable: true },
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        { key: "f_name", label: "Trainers", sortable: true },
        { key: "suspendDate", label: "Date",type: "date"},
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        {
          key: "specialized",
          label: "Specialized In Which Area",
          type: "dropdown",
          displayName: "name",
        },
        // { key: 'balance', sortable: true },
        { key: "phone", label: "Contact", sortable: true },
        { key: "suspend_type", label: "Type",},

        { key: "Action", label: "Action" },
      ],
      searchTerm: { name: "Trainer", value: "trainer" },
      search: "",
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      isSortDirDesc: true,
      congrate: false,
      SubmitTrainer: false,
      AddNewTrainer: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      fullname: "",
      passwordMismatch: false,
      password: "",
      selectedOption: null,
      confirm_password: "",
      passwordIcon: "password",
      confirmpasswordIcon: "password",
      gender: "",
      isLoading: false,
      address: "",
      UserID: "",
      height: "",
      weight: "",
      pagination: "",
      email: "",
      contact: "",
      language: "English",
      langOption: [],
      country: "",
      profileAttach: "",
      IDAttach: "",
      certiAttach: "",
      PtInsurance: "",
      insurance: "",
      weightloss: "",
      condition: "",
      strenth: "",
      plans: "",
      Title: "",
      files: "",
      plansOption: [],
      genderOption: [],
      ContryOption: [],
      specializedOption: [],
      staticUsers: [],
      suspendDate:[],
      ifEdit: false,
      id: 0,
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      columnFilters: {},
      params:{},
      searchTermOptions: [
        { name: "Trainer", value: "trainer" },
        { name: "Specialized", value: "specialized", type:'dropdown',displayName:'name',storename:'specialized' },
        { name: "Type", value: "suspend_type", type: "dropdown" ,options:['Permanent','Temporary'] },

      ],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ConfirmIcon() {
      return this.confirmpasswordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    setspecialization(){
      return(data)=>{
        if(data){

          let arr = data.map((item)=>{
            return item.specialization ? item.specialization.name :''
          })
          
          return arr.filter(item=> item ).join(', ')
        }
      return ''
      }
    }
  },
  watch: {
    password() {
      this.validatePasswordMatch();
    },
    confirm_password() {
      this.validatePasswordMatch();
    },
  },
  mounted() {
    this.getTrainerData();
  },
  methods: {
    openTrainerProfile(item) {
      const UserId = item.id;
      this.$router.push({ path: `/trainerprofile/${UserId}`,
      query: { type: "profile" } });
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getTrainerData();
    },
    handleSearchType(){
      this.search=''
      this.getTrainerData()
    },
    filterTable: _.debounce(function () {
      this.params = {};
      for (let key in this.columnFilters) {
        if (
          this.columnFilters[key] &&
          typeof this.columnFilters[key] == "object"
        ) {
          this.params[key] = this.columnFilters[key]
            .map((inner) => inner.id)
            .join(",");
        } else {
          this.params[key] = this.columnFilters[key];
        }
      }
      this.params.page = this.paginationValue;
      this.getTrainerData();
    }, 1000),
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },geSearch() {
      if (this.search) {
        this.getTrainerData();
      }
    },
    changePagination() {
      this.getTrainerData();
    },
    async getTrainerData() {
      this.isLoading = true;
      let params = {}
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      // let params = `?page=${this.paginationValue}&per_page=${this.per_page}${
      //   this.search ? "&search=" + this.search : ""
      // }${
      //   this.search && this.searchTerm ? "&type=" + this.searchTerm.value : ""
      // }`;
      await axios({
        method: "GET",
        url: `${this.baseApi}dashboard/suspend-trainer`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params:params,
      })
        .then((json) => {
          this.staticUsers = json?.data.data.data;
          this.suspendDate =
            this.staticUsers && this.staticUsers.length > 0
              ? this.staticUsers[0].suspend_date
              : "";
              // console.log(this.suspendDate,"date")
          this.pagination = json?.data.data;
          // console.log(this.pagination)
          this.paginationTotal = this.pagination.total;
          this.paginationStart = this.pagination.from;
          this.paginationEnd = this.pagination.to;
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(()=>{
          this.isLoading = false;
        })
    },
    handleSearch: _.debounce(function () {
      this.getTrainerData();
    }, 1000),

    async handleUnsuspend(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Unsuspend it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Update the API endpoint and payload for unsuspending
              const unsuspendData = {
                user_id:id
              };

              await axios({
                method: "post", // Change the HTTP method to POST
                url: `${this.baseApi}dashboard/unsuspend-user`, // Update the API endpoint
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.accessToken}`,
                },
                data: JSON.stringify(unsuspendData), // Pass the payload directly
              });

              this.$swal({
                title: "Unsuspended",
                text: "Trainer Unsuspended successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.getTrainerData();
            } catch (error) {
              console.error("Error unsuspending user:", error);
            }
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },

    handleTrainerSubmit() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success === false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.handleForm();
          this.getTrainerData();

          // this.congrate = true
        }
      });
    },

    Close() {
      this.AddNewTrainer = false;
    },
    Congo() {
      if (this.SubmitTrainer) {
        this.SubmitTrainer = false;
      }
      this.congrate = true;
    },
    Congratulation() {
      this.congrate = false;
    },
  },
};
</script>
<style>
.avatar-container {
  display: flex;
  align-items: center;
}

.remaining-count {
  margin-left: 5px;
  font-weight: bold;
}

.option {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
}

.option.selected {
  border: 1px solid #7367f0;
  color: #000;
}
</style>

